import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import { Link } from "gatsby"
import AsideItem from "../components/aside/item"

const Kurumsal = () => (
  <Layout>
    <SEO title="Kurumsal" />
    <div id="kurumsal" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Kurumsal" description="Kurumsal" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/kurumsal" title="Bizim Hikayemiz" icon="icon-serenay" />
			        	<AsideItem to="/kimlik" title="Kurumsal Kimlik" icon="icon-kariyer1" />
			        	<AsideItem to="/gizlilik" title="Gizlilik Güvenlik" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45">
	        	<article className="w100 left fs18">
	        		<p className="colored3">
	        		<b>Serenay Yazılım Ltd. Şti.</b> olarak <b>2006</b> yılından bu yana değerli müşterilerimize kurumsal web sayfası tasarımı, yazılım geliştirme çözümleri ve danışmanlık hizmetleri vermekteyiz. <b>Türkiye'nin dört bir yanına sağladığımız hizmetler</b> ile değerli müşterilerimize çözümler sunmanın gururunu yaşıyoruz. Projeniz mi var? Projelendirme süreçlerimize göz atın, referanslarımızı ve başarı hikayelerimizi inceleyin. Müşteri hizmetleri ve destek kanallarımızı kullanarak, proje detaylarınızı tarafımıza iletin. Belki aradığınız çözüm buradadır.
	        		</p>
	        		<p>
	        			<b className="w100 left lh38"><i className="icon icon-work mr10 left p10 b-circle colored3-bg white"></i>Projelendirme Süreçleri</b> 
	        			<i className="fs20 fw5 colored3 pl45 col-xs-pl0 left">Projelendirme süreçlerinde ihtiyaçlarınızı en hızlı şekilde ürüne dönüştürmek için; çevik uygulama metodlarını kullanmaktayız. Projelendirme aşamalarında esas aldığımız hususlar başlıca şunlardır;</i>
	        		</p>
	        		<div>
	        			<ul className="w100 left ml25 pl20 col-xs-ml0 pt25 pb25 mt15 mb15 b-ra5 bs3" style={{backgroundColor:"#f7f7f7"}}>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>İhtiyaçlarınızın sizin tarafınızdan belirlenmesi ve taleplerinizin tarafımıza bildirilmesi</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>İhtiyaçlarınızın uzman sistem analisti ekibimiz tarafından yerinde incelenmesi</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Aciliyet durumlarına göre yol haritasının belirlenmesi</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Uygulamanın geliştirilebilir ölçekte olması adına altyapı çalışmalarının sağlanması</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Uygulama geliştirme süreci ve testlerin hazırlanması</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Üretim ortamında testlerin yapılması</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Uygulamanın hazır halde devri</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Destek hizmetlerinin verilmesi</li>

	        			</ul>
	        		</div>
	        		<p>
	        			<Link className="colored2 p15 mt15 wi left b-ra5 fw6" to="/cozumler">Çözümlerimizi İnceleyin <i className="icon icon-right5 fs8"></i></Link>
	        		</p>
	        	</article>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Kurumsal
